document.addEventListener("DOMContentLoaded", function () {
  var mapExists = document.querySelector(".gmap");
  if (mapExists) {
    var infowindow = new google.maps.InfoWindow({
      content: "",
    });
    var gmarkers1 = [];

    //init google maps
    function initialize() {
      
      var center = new google.maps.LatLng(defaultLat, defaultLng);

      var mapOptions = {
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoom: defaultZoom,
        zoomControl: true,
        scaleControl: true,
        center: center,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
            {
                "featureType": "administrative.locality",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#2c2e33"
                    },
                    {
                        "saturation": 7
                    },
                    {
                        "lightness": 19
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "saturation": "-3"
                    }
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#f39247"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#ffffff"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#ffffff"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.school",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#f39247"
                    },
                    {
                        "saturation": "0"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ff6f00"
                    },
                    {
                        "saturation": "100"
                    },
                    {
                        "lightness": 31
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#f39247"
                    },
                    {
                        "saturation": "0"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#008eff"
                    },
                    {
                        "saturation": -93
                    },
                    {
                        "lightness": 31
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#f3dbc8"
                    },
                    {
                        "saturation": "0"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#bbc0c4"
                    },
                    {
                        "saturation": -93
                    },
                    {
                        "lightness": -2
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#e9ebed"
                    },
                    {
                        "saturation": -90
                    },
                    {
                        "lightness": -8
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#e9ebed"
                    },
                    {
                        "saturation": 10
                    },
                    {
                        "lightness": 69
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#e9ebed"
                    },
                    {
                        "saturation": -78
                    },
                    {
                        "lightness": 67
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            }
        ]
      };

      map = new google.maps.Map(document.getElementById("map"), mapOptions);

      for (i = 0; i < markers1.length; i++) {
        addMarker(markers1[i]);
      }

      infoWindow = new google.maps.InfoWindow();
      const locationButton = document.createElement("button");

      locationButton.textContent = "Twoja pozycja";
      locationButton.classList.add("custom-map-control-button");
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        locationButton
      );
      locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              infoWindow.setPosition(pos);
              infoWindow.setContent("Tu jesteś");
              infoWindow.open(map);
              map.setCenter(pos);
            },
            () => {
              handleLocationError(true, infoWindow, map.getCenter());
            }
          );
        } else {
          // Browser doesn't support Geolocation
          handleLocationError(false, infoWindow, map.getCenter());
        }
      });

      function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
          browserHasGeolocation
            ? "Error: The Geolocation service failed."
            : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
      }
    }
    /**
     * Function to add marker to map
     */

    function addMarker(marker) {
      const contentString = `
      <div
      id="content"
      class="tooltip_content"
      style="
        max-width: 250px;        
        padding: 7px;
        background: #272727;
        color: #fff;
        height: 100%;
      "
    >
      <a class="mapTooltip" href="${marker[8]}">
        <div id="siteNotice"></div>
        <div id="bodyContent">
          <img
            style="
              min-width: -webkit-fill-available;
              max-width: 100%;              
            "
            src="${marker[6]}"
          />
          <div style="padding: 15px">
          <h3
          id="firstHeading"
          class="firstHeading"
          style="font-size: 16px; margin-bottom: 10px"
          >
          <span style="margin-bottom: 5px" uk-icon="icon: location"></span>
              ${marker[1]}
            </h3>    
            <span class="green_dot uk-margin-small-top" style="gap: 0">
              <i style="background-color: #ef8354" uk-icon="icon: chevron-right; ratio: 1" class="uk-icon"></i>Czytaj
              więcej
            </span>
          </div>
        </div>
      </a>
    </div>
    
            `;
      var category = marker[4];
      var title = marker[1];
      var pos = new google.maps.LatLng(marker[2], marker[3]);
      // var content = marker[1];
      var content = contentString;
      var markerImg = marker[5];
      var markerPhoto = marker[6];
      var shortDesc = marker[7];
      var tooltipLink = marker[8];

      marker1 = new google.maps.Marker({
        title: title,
        position: pos,
        category: category,
        map: map,
        icon: markerImg,
        img: markerPhoto,
        desc: shortDesc,
        link: tooltipLink,
      });

      gmarkers1.push(marker1);

      // Marker click listener
      google.maps.event.addListener(
        marker1,
        "click",
        (function (marker1, content) {
          return function () {
            infowindow.setContent(content);
            infowindow.open(map, marker1);
            map.panTo(this.getPosition());
            map.setZoom(15);
          };
        })(marker1, content)
      );
    }

    //listener on checkboxes checks (filters)
    let checkboxes = $("input[type=checkbox]");
    let enabledSettings = [];

    checkboxes.change(function () {
      enabledSettings = checkboxes
        .filter(":checked") // Filter out unchecked boxes.
        .map(function () {
          // Extract values using jQuery map.
          return this.value;
        })
        .get(); // Get array.

      let el = $(this);
      if (el.is(":checked")) {
        categoryArray.push(el.data("filter"));
      } else {
        categoryArray = categoryArray.filter(function (value, index, arr) {
          return value != el.data("filter");
        });
      }

      filterMarkers();
    });

    //Function to filter markers by category
    let categoryArray = [];

    filterMarkers = function () {
      for (i = 0; i < markers1.length; i++) {
        marker = gmarkers1[i];
        // If is same category or category not picked
        if (
          categoryArray.includes(marker.category) ||
          categoryArray.length === 0
        ) {
          marker.setVisible(true);
        }
        // Categories don't match
        else {
          marker.setVisible(false);
        }
      }
    };

    // Init map
    initialize();
  }
});
